import { React, useContext, useEffect, useState } from 'react'
import './Form.css'
import { UserContext } from '../../Context/UserContext';
import API from '../../APIs/MainApi';
import { useTelegram } from '../../hooks/useTelegram';
import Timer from '../Utils/Timer/Timer';

function Form({Event, setModalData, currentUserData}) {

    const context = useContext(UserContext)

    const [firstName, setFirstName] = useState('');
    const [secondName, setSecondName] = useState('');
    const [thirdName, setThirdName] = useState('')
    const [gradeNum, setGradeNum] = useState();
    const [gradeLetter, setGradeLetter] = useState('');
    const [isGraduate , setIsGraduate] = useState(false)
    const [graduateYear, setGraduateYear] = useState('')

    const [ error, setError ] = useState()
    const [errorFirName, seterrorFirName] = useState(false);
    const [errorSecName, seterrorSecName] = useState(false);
    const [errorThirdName, seterrorThirdName] = useState(false);

    const [isAlreadyReg, setIsAlreadyReg] = useState(false)
    const [isRegNotOpened, setIsRegNotOpened] = useState(false)
    const [ isNotEnoughtSpace, setIsNotEnoughtSpace ] = useState(false)

    const [isDisabled, setisDisabled] = useState(true);

    const [validationSucces, setValidationSucces] = useState(false)

    const { user } = useTelegram()

    useEffect(() => {
        console.log(currentUserData);
        if(currentUserData) {
            setFirstName(currentUserData?.firstName)
            setSecondName(currentUserData?.secondName)
            setGradeNum(currentUserData?.gradeNum)
            setGradeLetter(currentUserData?.gradeLetter)
            
        }
    }, [currentUserData])

    const validation = () => {
        console.log(context);
        seterrorFirName(false)
        seterrorSecName(false)
        if (firstName.length < 2){
            seterrorFirName('Имя не может быть короче 2 символов')
            setisDisabled(true)
        }
        if (firstName.length > 15) {
            seterrorFirName('Имя не может быть длиннее 15 символов')
            setisDisabled(true)
        }
        if (secondName.length < 2) {
            seterrorSecName('Фамилия не может быть короче 2 символов')
            setisDisabled(true)
        }
        if (secondName.length > 15) {
            seterrorSecName('Фамилия не может быть длиннее 15 символов')
            setisDisabled(true)
        }
        if (thirdName.length < 2) {
            seterrorThirdName('Отчество не может быть короче 2 символов')
            setisDisabled(true)
        }
        if (thirdName.length > 15) {
            seterrorThirdName('Отчество не может быть длиннее 15 символов')
            setisDisabled(true)
        }
        if (gradeNum === '') {
            setisDisabled(true)
        }
        if (gradeLetter === '') {
            setisDisabled(true)
        }

        if (!(firstName.length < 2) && !(firstName.length > 15) && !(secondName.length < 2) && !(secondName.length > 15) && (gradeNum !== '') && (gradeLetter !== '')) {
            setError('')
            setValidationSucces(true)
            console.log('Validation succes');
        }

    }

    const handleNewMember = () => {
        let regData = {}
        if(context?.userIDContext) {
            regData = {
                firstName: firstName,
                secondName: secondName,
                gradeNum: gradeNum,
                gradeLatter: gradeLetter,
                isGraduate: isGraduate,
                graduateYear: graduateYear
            }
        }
        else {
            regData = {
                firstName: firstName,
                secondName: secondName,
                thirdName: thirdName,
                gradeNum: gradeNum,
                gradeLatter: gradeLetter,
                isGraduate: isGraduate,
                graduateYear: graduateYear
            }
        }
        
        API.addmember(Event._id, context?.userIDContext, regData).then((data) => {
             if (!(context?.userIDContext)) {
                setValidationSucces(false)
                setModalData({message: 'Пропуск на мероприятие отправлен в сообщения', type: 'ok'})

                // 824398251
                //API.sendqr(chat.id, data.memberData) 

                API.sendqr(user.id, data.memberData, Event._id).then((data) => {
                    console.log(data);
                }).catch((e) => {
                    console.log(e);
                })
             }

             setModalData({message: 'Вы зарегистрировались на мероприятие!\nЕсли пропуск не появится через секунды, перезагрузите страницу', type: 'ok'})
             setValidationSucces(false)
        }).catch((e) => {
            console.log(e);
            setError(e.response.data.message)
            setModalData({message: e.response.data.message, type: 'error'})
        })
    }

    useEffect(() => {
        if ((firstName !== '') && (secondName !== '') && (gradeNum !== '') && (gradeLetter !== '')){
            setisDisabled(false)
        }
    }, [firstName, secondName, gradeNum, gradeLetter])

    useEffect(() => {
        if(context?.userEventsContext) {
            if(context?.userEventsContext?.length !== 0) {
                if(context?.userEventsContext?.find(o => o == Event._id)) {
                    setIsAlreadyReg(true)
                    console.log(context?.userEventsContext);
                }
            }
            else {
                setIsAlreadyReg(false) 
            }
        }
    })

    useEffect(() => {
        if(Event) {
            if(Event?.spaceAvaileble == Event?.members?.length){
                if(!isAlreadyReg) {
                    setIsNotEnoughtSpace(true)
                }
            }
        }
    })

    useEffect(() => {
        if (Event) {
            let year = `20${Event?.registrationDate.split(".")[2]}`
            let mounth = Event?.registrationDate.split(".")[1]
            let day = Event?.registrationDate.split(".")[0]

            let dateDead = new Date(Number(year), Number(mounth)-1, Number(day))

        if (dateDead > new Date()) {
            setIsRegNotOpened(true)
        }
        }
    }, [Event])

  return (<>
    {isAlreadyReg ? <div className='formAlreadyMember'><p>Вы уже зарегистрированы на это мероприятие</p>
    </div> : null }
    {isNotEnoughtSpace ? <div className='formNotStartedYet'><p>К сожалению, места закончились(</p>
    <p>Будем ждать на других мероприятиях</p>
    </div> : null }
    {isRegNotOpened ? <div className='formNotStartedYet'><p>Регистрация на мероприятие еще не началась</p>
    <p>Регистрация начнется через: <Timer date={Event?.registrationDate}/></p>
    </div> : null }
    <section className={'formWraper'}>
        {!validationSucces ?
         (<div className={"form"}>
         <h1>Регистрация на мероприятие:</h1>
         <h1 className='FormEventName'>{Event?.title}</h1>

         <h3>Введите ваше Имя:</h3>
         <input className={'input'} type="text" placeholder={'Имя'} value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
         <span className='errorForm'>{errorFirName ? errorFirName : null}</span>

         <h3>Введите вашу Фамилию:</h3>
         <input className={'input'} type="text" placeholder={'Фамилия'} value={secondName} onChange={(e) => setSecondName(e.target.value)}/>
         <span className='errorForm'>{errorSecName ? errorSecName : null}</span>

         {context?.userIDContext
         ? null
         : (<>
            <h3>Введите ваше Отчество:</h3>
            <input className={'input'} type="text" placeholder={'Отчество'} value={thirdName} onChange={(e) => setThirdName(e.target.value)}/>
            <span className='errorForm'>{errorThirdName ? errorThirdName : null}</span>
            </>)
         }

         <h3>Выберете ваш Класс:</h3>
         <div className={'grade-submit'}>
             <div className="gradeSelects">
                 <select className={'select gradeNum'} name="" id="" value={gradeNum ? gradeNum : 'Цифра' } onChange={(e) => setGradeNum(e.target.value)}>
                     <option value="Цифра" disabled></option>
                     <option value={9}>9</option>
                     <option value={10}>10</option>
                     <option value={11}>11</option>
                 </select>
                {gradeNum ? <select className={'select gradeLetter'} name="" id="" value={gradeLetter ? gradeLetter : 'Буква' } onChange={(e) => setGradeLetter(e.target.value)}>
                <option value="Буква" disabled></option>
                 {gradeNum == 9
                 ? <>
                        <option value="А">А</option>
                        <option value="Б">Б</option>
                        <option value="В">В</option>
                        <option value="Г">Г</option>
                        <option value="Д">Д</option>
                        <option value="Е">Е</option>
                        <option value="Ж">Ж</option>
			<option value="З">З</option>
                    </>
                 : null
                 }
                 {gradeNum == 10
                 ? <>
                        <option value="А">А</option>
                        <option value="Б">Б</option>
                        <option value="В">В</option>
			<option value="Г">Г</option>
                    </>
                 : null
                 }

                {gradeNum == 11
                 ? <>
                        <option value="А">А</option>
                        <option value="Б">Б</option>
			<option value="В">В</option>
                    </>
                 : null
                 }
                    </select> : null}
                 
             </div>
             <div className="submit-block">
                 <button className='submit-button' disabled={isDisabled} onClick={() => validation()}>Регистрация</button>
             </div>
         </div>
     </div>) 
         : 
         ( <div className="form">
            <h1>
                Проверьте данные:
            </h1>
            <div className="firAndSecNameCheck">
                <h3 className='FIO'>Ты: <span className='checkPlaces'>{firstName} {secondName}</span></h3>
                <h3>Из: <span className='checkPlaces'>{gradeNum} {gradeLetter}</span> класса</h3>
            </div>
            <div className="check">
                <h3>Всё верно?</h3>
                <div className="checkButtons">
                    <button className='checkConfirm' onClick={() => handleNewMember()}>Да ✅</button>
                    <button className='checkDecline' onClick={() => setValidationSucces(false)}>Нет ❌</button>
                </div>
            </div>
            <span className='error'>{error}</span>
            <span className='DangerPrompt'>Помните, все данные проверяются.</span>
            <span className='DangerPrompt NotAllowPrompt'>Администрация вправе не допускать участников до меропрятия, без объяснения причин. </span>
         </div> )
         }
        
    </section>
  </>)
}

export default Form
