import React from 'react'
import './Author.css'
import zxcCat from '../../images/zxc-cat-png.gif'
import { Link } from 'react-router-dom'
import git from '../../images/gitroundlogosmall.png'
import vk from '../../images/vkroundiconsmall.png'
import tgicon from '../../images/tgroundiconsmall.png'

function Author() {
  return (
    <section className={'author'}>
        <div className="authorData">
            <div className="topAuthorData">
                <h3>Автор бота</h3>
                <Link className='tgLink' to="https://t.me/vlxdxz" target='_blank'>@vlxdxz</Link>
            </div>
            <div className="bottomAuthorData">
                <Link to="https://vk.com/vlxdxz" target='_blank'>
                    <img className={'socMediaIcons'} src={vk} alt="vk" />
                </Link>
                <Link to="https://t.me/vlxdxz" target='_blank'>
                    <img className={'socMediaIcons'} src={tgicon} alt="tg" />
                </Link>
                <Link to="https://github.com/Dmi3evVladislav" target='_blank'>
                    <img className={'socMediaIcons gitIcon'} src={git} alt="git" />
                </Link>
            </div>
        </div>
        <div className={"zxc"}>
            <img className={'zxcGif'} src={zxcCat} alt="zxccat" />
        </div>
    </section>
  )
}

export default Author