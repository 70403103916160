const tg = window.Telegram.WebApp;

export function useTelegram() {

    const close = () => {
        tg.close()
    }
    const toogleButton = () => {
        if (tg.MainButton.isVisible) {
            tg.MainButton.hide();
        }
        else {
            tg.MainButton.show();
        }
    }

    return {
        close,
        toogleButton,
        tg,
        user: tg.initDataUnsafe?.user,
        chat: tg.initDataUnsafe?.chat
    }
}