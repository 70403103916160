import React from 'react'
import './Modal.css'

const Modal = ({ModalData, CloseModal}) => {
  return (
    <div className="Modal">
    <div className={`ModalData ${ModalData?.type === 'danger' ? 'dangerModal': ''} ${ModalData?.type === 'ok' ? 'okModal': ''} ${ModalData?.type === 'dev' ? 'devModal': ''} ${ModalData?.type === 'error' ? 'errorModal': ''}`}>
            <h3>{ModalData?.message}</h3>
            {ModalData?.type === 'danger' ? <button onClick={() => CloseModal()}>Понятно</button> : <button onClick={() => CloseModal()}>Окей</button>}
        </div>
    </div>
  )
}

export default Modal