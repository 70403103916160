import React, { useEffect, useState } from 'react'
import './EventsPage.css'
import Block from '../Block/Block'
import Event from '../Event/Event'
import API from '../../APIs/MainApi'

function EventsPage() {

const [ events, setEvents ] = useState([])
const [ sortEvents, setSortEvents ] = useState([])

useEffect(() => {
    API.getevents().then((data) => {
        setEvents(data)
    })
}, [])

const daySort = (a, b) => {
    let dateA = new Date(Number(20 + a.date.split(".")[2]), Number(a.date.split(".")[1]), Number(a.date.split(".")[0]))
    let dateB = new Date(Number(20 + b.date.split(".")[2]), Number(b.date.split(".")[1]), Number(b.date.split(".")[0]))

    return dateB - dateA;
}

useEffect(() => {
    if(events){
        setSortEvents(events.sort(daySort).reverse());
    }
}, [events])


  return (
    <main className='EventsPage container'>
        {events.length !== 0
        ?   sortEvents.map((event) => 
            <Block key={event._id}>
                <Event Event={event}/>
            </Block>
             
            )
        : <Block><p className='EventsPage__no-events'>Ближайших мероприятий нет(</p></Block> 
        }
    </main>
  )
}

export default EventsPage