import React from 'react'
// import Button from '../Button/Button'
import { useTelegram } from '../../hooks/useTelegram'
import '../Header/Header.css'
import Block from '../Block/Block'
import Logo from '../../images/Party46Project.png'
import { NavLink } from 'react-router-dom'

function Header({menuOpenHandler}) {
    const {user} = useTelegram()

  return (
    <header className={'header-wraper'}>
      <Block>
      
        <div className={'header'}>
          <NavLink to="/">
            <img className='Logo' src={Logo} alt="" />
          </NavLink>
          <div className="BotNameAndUser">
            <h1>Party 46 Project Bot</h1>
            <p className={'username'}>{user?.username}</p>
          </div>
          <button className='submit-button' onClick={menuOpenHandler}>Меню</button>
        </div>
    </Block>
    </header>
  )
}

export default Header