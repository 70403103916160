import React, { useEffect, useState } from 'react'
import './Timer.css'

function Timer({date}) {
    let year = `20${date.split(".")[2]}`
    let mounth = date.split(".")[1]
    let day = date.split(".")[0]
    const dateDead = new Date(Number(year), Number(mounth)-1, Number(day))
    const finishTime = dateDead.getTime();
    const [[diffDays, diffH, diffM, diffS], setDiff] = useState([0, 0, 0, 0]);
    const [tick, setTick] = useState(false);
    const [alreadyStart, setAlreadyStart] = useState(false)

    useEffect(()=> {
        const diff = (finishTime - new Date()) / 1000;
        if (diff < 0){
            setAlreadyStart(true)
        } // время вышло
        setDiff([
          Math.floor(diff / 86400), // дни
          Math.floor((diff / 3600) % 24) < 10 ? "0" +  Math.floor((diff / 3600) % 24) : Math.floor((diff / 3600) % 24), 
          Math.floor((diff / 60) % 60) < 10 ? "0" + Math.floor((diff / 60) % 60) : Math.floor((diff / 60) % 60), 
          // Math.floor(diff % 60),
          Math.floor(diff % 60) < 10 ? "0" + Math.floor(diff % 60) : Math.floor(diff % 60)
        ]) 
      }, [tick, finishTime])
          
      useEffect(()=>{
        const timerID = setInterval(() => setTick(!tick), 1000);
        return () => clearInterval(timerID);
      }, [tick])

  return (
    alreadyStart ? ( <span className='temerEnd'>Сейчас</span> ) : ( <span className='timer'>{diffDays} дн. {diffH}:{diffM}:{diffS}</span> )
  )
}

export default Timer