import constants from "../utils/constants.js";
import axios from "axios";

class Api {
    constructor ({ serverUrl }) {
        this._serverUrl = serverUrl 
    }

    async _requestResult (res) {
        const result = await res.json()
        return res.ok ? result : Promise.reject(result.message)
    }

    async getevents() {
        const res = await axios.get(`${this._serverUrl}/getevents`) 
        return res.data
    }
    async getadmindata(login, password) {
        const res = await axios.post(`${this._serverUrl}/getadmindata`, {login: login, accessKey: password})
        return res.data
    }
    async addevent(login, accessKey, confimCode, title, topic, date, startTime, duration, place, spaceAvaileble, registrationDate, openDorsTime) {
        const res = await axios.put(`${this._serverUrl}/addevent`, {
            login: login,
            accessKey: accessKey,
            confirmCode: Number(confimCode),

            title: title,
            topic: topic,
            date: date,
            startTime: startTime,
            duration: duration,
            place: place,
            spaceAvaileble: spaceAvaileble,
            registrationDate: registrationDate,
            openDorsTime: openDorsTime
        })
        return res.data
    }

    async getuser(userID) {
        console.log(userID);
        const res = await axios.post(`${this._serverUrl}/getuser`, {telegramID: userID})
        return res.data
    }

    

    async addmember(eventID, userID, regData) {
        if (userID) {
            const res = await axios.put(`${this._serverUrl}/addmember`, {eventID: eventID, userID: userID, regData: regData})
            return res.data 
        }
        else {
            const res = await axios.put(`${this._serverUrl}/addmember`, {eventID: eventID, regData: regData})
            return res.data 
        }
    }

    async sendqr(chatID, cardData, eventID) {
        const res = await axios.post(`${this._serverUrl}/sendqr`, {chatID: chatID, cardData: cardData, eventID})
        return res.data
    }

    async deleteevent(login, accessKey, confirmCode, id) {
        const res = await axios.post(`${this._serverUrl}/deleteevent`, {login: login, accessKey: accessKey, confirmCode: confirmCode, id: id })
        return res.data
    }

    async deletemember(memberID, eventID, login, accessKey) {
        const res = await axios.put(`${this._serverUrl}/deletemember`, {userID : memberID, eventID : eventID, login: login, accessKey: accessKey})
        return res.data
    }

    async getblacklist(login, accessKey) {
        const res = await axios.post(`${this._serverUrl}/getBlackList`, {login: login, accessKey: accessKey})
        return res.data
    }

    async addblacklist(firstName, secondName, gradeNum, gradeLatter, isGraduate, graduateYear, login, accessKey) {
        const res = await axios.put(`${this._serverUrl}/addblacklist`, {firstName, secondName, gradeNum, gradeLatter, isGraduate, graduateYear, login, accessKey})
        return res.data
    }

    async deleteblack(id, login, accessKey) {
        const res = await axios.post(`${this._serverUrl}/deleteblacklist`, {id, login, accessKey})
        return res.data
    }

}

const API = new Api({
    serverUrl: constants.serverUrl
})

export default API

