import React from 'react'
import './Event.css'
import Timer from '../Utils/Timer/Timer'

function Event({ Event }) {
  return (
    <section className='Event' key={Event.id}>
        {Event ? 
        ( <div className="EventData">
            <h1 className='EventName'>{Event?.title}</h1>
            <h3 className='EventTopic'>{Event?.topic}</h3>
            <p>Запланированно: <span className='EventInfoTileData'>{Event?.date}</span></p>
            <p>Начало: <span className='EventInfoTileData'>{Event?.startTime}</span></p>
            <p>Продолжительность: <span className='EventInfoTileData'>{Event?.duration}</span></p>
            <p>Вход: <span className='EventInfoTileData'>{Event?.openDorsTime}</span></p>
            <p>Место проведения: <span className='EventInfoTileData'>{Event?.place}</span></p>
            <p>Осталось мест: <span className='EventInfoTileData'>{(Event?.spaceAvaileble) - (Event?.members?.length)}/{Event?.spaceAvaileble}</span></p>
            <p className="regstart">Регистрация через: <Timer date={Event?.registrationDate}/></p>
        </div> ) 
        : 
        (<h1 className='EventName'>Ближайших мероприятий нет (</h1>)
        }
    </section>
  )
}

export default Event