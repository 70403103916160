import { useContext, useEffect, useState } from 'react';
import './App.css';
import { useTelegram } from './hooks/useTelegram';
import Header from './components/Header/Header';
import { Route, Routes} from 'react-router-dom'
import Main from './components/Main/Main';
import Menu from './components/Menu/Menu';
// import Form from './components/Form/Form';
import './components/Menu/Menu.css'
import EventsPage from './components/EventsPage/EventsPage';
import VL from './images/VLLogo.png'
import AdminPanel from './components/AdminPanel/AdminPanel';
import Chalenges from './components/Chalenges/Chalenges';
import { UserContext } from './Context/UserContext';
import API from './APIs/MainApi';
import Modal from './components/Modal/Modal';
import Scaner from './components/Scaner/Scaner';

function App() {
  const {tg, user, chatInst, rowInitDatatg, rowTg} = useTelegram()
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [ currentUserContext, setCurrentUserContext ] = useState()
  const [currentUserData, setCurrentUserData] = useState()
  const [ danger, setDanger ] = useState()
  const context = useContext( UserContext )
  const [tgState, setTgState] = useState(false)

  const [ ModalData, setModalData] = useState('')
  const [ isModalShow, setIsModalShow ] = useState(false)

  const [isNeedUpdate, setIsNeedUpdate] = useState(false)

  useEffect(() => {
    if (ModalData !== '') {
        setIsModalShow(true)
    }
}, [ModalData])

const closeModal = () => {
  setIsModalShow(false)
  setModalData('')
  setIsNeedUpdate(true)

}


  useEffect(() => {
    tg.ready()
    tg.expand()
    setTgState(true)
  })

  useEffect(() => {
        if (context?.isSavedContext) {
           return
         }
         else  {
          console.log(context?.isSavedContext);
          API.getuser(user?.id).then((data) => {
            if(data.danger) {
              setDanger(data.danger)
              setCurrentUserContext({dangerContext: data?.danger, isSavedContext: true})
              return
            }
            setCurrentUserContext({userIDContext: data?.user._id, userEventsContext: data?.user?.events, isSavedContext: true})
            setCurrentUserData(data?.user)
            console.log(data);
          }).catch((e) => {
            console.log(e);
          })
         }
  }, [tgState, isModalShow])

  useEffect(() => {
    if (danger) {
      setModalData({message: danger, type: 'danger'})
    }
  }, [danger])

  const menuOpenHandler = () => {
      setIsMenuOpen(true);
  };

  const menuCloseHandler = () => {
    setIsMenuOpen(false);
  };

  const showRowData = () => {
    console.log(rowInitDatatg);
    console.log('//////');
    console.log(rowTg);
  }

  return (
    <div className="App">
      <UserContext.Provider value={currentUserContext}>
        <Header menuOpenHandler={menuOpenHandler}/>

        {isModalShow ? <Modal ModalData={ModalData} CloseModal={closeModal}/> : null}

        <Routes className="container">
          <Route index path="/" element={<Main setModalData={setModalData} isNeedUpdate={isNeedUpdate} setIsNeedUpdate={setIsNeedUpdate} currentUserData={currentUserData}/>}/>
          <Route path="/events" element={<EventsPage />}/>
          <Route path="/admin" element={<AdminPanel setModalData={setModalData}/>}/>
          <Route path="/chalenges" element={<Chalenges setModalData={setModalData} currentUserData={currentUserData}/>}/>
          <Route path='/scaner' element={<Scaner/>}/>
        </Routes>
        <Menu isMenuOpen={isMenuOpen} closed={menuCloseHandler}/>
        <p className='Version' onClick={() => {showRowData()}}>V 0.5 Beta-release</p>
        <div className="VLlogoBox">
          <img className='VLlogo' src={VL} alt="" />
        </div>
      </UserContext.Provider>
    </div>
  );
}

export default App;
