import React, { useContext, useEffect, useState } from 'react'
import './Main.css'
import Form from '../Form/Form'
import Block from '../Block/Block'
import Author from '../Author/Author'
import Event from '../Event/Event'
import { NavLink } from 'react-router-dom'
import API from '../../APIs/MainApi'
import QRBlock from '../QRBlock/QRBlock'
import Preloader from '../Preloader/Preloader'
import { UserContext } from '../../Context/UserContext'

function Main({setModalData, isNeedUpdate, setIsNeedUpdate, currentUserData}) {

    const [ events, setEvents ] = useState([])
    const [ nearest, setNearest ] = useState('')

    const [ neadUpdate, setNeedUpdate ] = useState(false)

    const [ isLoadedNearestEvent, setIsLoadedNearestEvent ] = useState(false)

    const [ isContextLoad, setIsContextLoad ] = useState(false)

    const context = useContext(UserContext)

    useEffect(() => {
        if (isNeedUpdate) {
            setIsLoadedNearestEvent(false)
            setNeedUpdate(true)
            setIsNeedUpdate(false)
        }
    }, [isNeedUpdate])


    useEffect(() => {
        API.getevents().then((data) => {
            setEvents(data)
        })

    }, [])
    useEffect(() => {
        const getNewEvents = () => {
            API.getevents().then((data) => {
                setEvents(data)
            })
        }
        getNewEvents()
        setNeedUpdate(false)
    }, [isNeedUpdate])

    useEffect(() => {
        if(context?.userIDContext) {
            setIsContextLoad(true)
        }
    }, [context])

    useEffect(() => {
        if (events) {
            let nearest = ''
            events.map((event) => {
                if (nearest !== '') {

                    let nearestDate = new Date(Number(20 + nearest.date.split(".")[2]), Number(nearest.date.split(".")[1]), Number(nearest.date.split(".")[0]))
                    let eventDate = new Date(Number(20 + event.date.split(".")[2]), Number(event.date.split(".")[1]), Number(event.date.split(".")[0]))

                    if (eventDate <= nearestDate) {
                        nearest = event
                    }
                    else {
                        nearest = nearest
                    }
                }
                else {
                    nearest = event
                }
            })
            setNearest(nearest)
            setIsLoadedNearestEvent(true)
        }
        else {
            return
        }
    }, [events])


  return (
    <main className={'main container'}>
        {events.length !== 0 
        ? (<>
        {isContextLoad ? <Block>
            <QRBlock Event={nearest} setModalData={setModalData} isLoadedNearestEvent={isLoadedNearestEvent}/>
        </Block> : null}
        <Block>
            <Form Event={nearest} setModalData={setModalData} currentUserData={currentUserData}/>
        </Block>
        <Block>
            <Event Event={nearest}/>
        </Block>
        <NavLink className={'buttonToAllEvents'} to="/events">Все мероприятия</NavLink>
        </>)
        : <Block><p className='EventsPage__no-events'>Ближайших мероприятий нет(</p></Block>
        }
        
        <Block>
            <Author />
        </Block>
    </main>
  )
}

export default Main