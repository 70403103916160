import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTelegram } from '../../hooks/useTelegram'

function Menu({isMenuOpen, closed}) {

    const {close} = useTelegram()

  return (
    <div className={`Menu ${isMenuOpen ? 'Menu__Opened' : 'Menu__Closed'}`}>
        <div className="menuWraper">
            <h1 className='menu_title'>Меню</h1>
            <nav className="navbuttns">
                <NavLink className={'menuRoute'} to="/" onClick={closed}>Главная</NavLink>
                <NavLink className={'menuRoute'} to="/chalenges" onClick={closed}>Номинации</NavLink>
                <NavLink className={'menuRoute'} to="/events" onClick={closed}>Мероприятия</NavLink>
                <NavLink className={'menuRouteAdmins'} to="/admin" onClick={closed}>Для Админов</NavLink>
            </nav>
            <button className='close_menu-button' onClick={closed}>Закрыть меню ✖</button>
            <button className='header_close-butt' onClick={close}>Выйти из приложения</button>
        </div>
    </div>
  )
}

export default Menu