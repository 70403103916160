import { Html5Qrcode } from 'html5-qrcode';
import React, { useEffect, useState } from 'react'
import './Scaner.css'
import API from '../../APIs/MainApi';

const Scaner = () => {
    const [events, setEvents] = useState()
    const [selectedEvent, setSelectedEvents] = useState()
    const [isPageOpen, setPageOpen] = useState() // id ивента

    const [selectedEventMembers, setSelectedEventMembers] = useState ()

    const [isGood, setGood] = useState(false)
    const [isBad, setBad] = useState(false)

    const [memberData, setMemberData] = useState()


    const [isEnabled, setEnabled] = useState(false)
    const [qrMessage, setQrMessage] = useState('')

    useEffect(() => {
        console.log('open');
        setPageOpen(true)
    })

    useEffect(() => {
        if (events && selectedEvent) {
            setSelectedEventMembers(events?.find(o => o._id == selectedEvent)?.members)
        }
    }, [selectedEvent])

    useEffect(() => {
        console.log(selectedEventMembers);
    }, [selectedEventMembers])

    useEffect(() => {
        console.log('loadevents');
        API.getevents().then((data) => {
            setEvents(data)
        }).catch((e) => {
            console.log(e);
        })
    }, [isPageOpen])

    const setIsGood = () => {
        setGood(true)
        setBad(false)
    }

    const setIsBad = () => {
        setGood(false)
        setBad(true)
    }

    useEffect(() => {
        const checked = selectedEventMembers?.find(o => o._id == qrMessage)
        if (checked && isEnabled) {
            setIsGood()
            console.log('good');
            setMemberData(checked)

        } else if (!checked && isEnabled) {
            setIsBad()
            console.log('bad');
            setMemberData()
        }
    }, [qrMessage])

    useEffect(() => {
        const config = { fps: 10, qrbox: { width: 200, height: 200 } };
        const html5QrCode = new Html5Qrcode("qrCodeContainer")

        const qrScanerStop = () => {
            if (html5QrCode && html5QrCode.isScanning) {
                html5QrCode.stop().then(() => {
                    console.log("Scaner stop");
                }).catch(() => {
                    console.log("Scaner error");
                })
            }
        }

        const qrCodeSuccess = (decodedText) => {
            setQrMessage(decodedText);
            console.log(memberData);
            //setEnabled(false)
        }

        if (isEnabled) {
            html5QrCode.start({ facingMode: "environment" }, config, qrCodeSuccess)
            setQrMessage('')
            setGood(false)
            setBad(false)
        } else {
            qrScanerStop()
        }

        return (() => {
            qrScanerStop()
        })

    }, [isEnabled])
    return (
        <main className='scaner'>
            <div className={`scanerhead ${isGood? `screenGood` : ''} ${isBad ? 'screenBad' : ''}`}>
                <p className='scanerselecteventtext'>Выберете мероприятие</p>
                <select name="events" value={selectedEvent ? selectedEvent : 'Мероприятие'} onChange={(e) => setSelectedEvents(e.target.value)} className='eventSelecterScaner'>
                    <option disabled>Мероприятие</option>
                    {events?.map((event) =>
                        <option value={event._id} key={event._id}>{event.title}</option>
                    )}
                </select>
                <div className="mes">
                    {memberData && <div className="memberData">  <p>{memberData?.firstName} {memberData?.secondName}</p> <p>{memberData?.gradeNum}{memberData?.gradeLatter}</p> </div> }
                    {qrMessage && <div className='qr-message' key={qrMessage}>{qrMessage}</div>}
                </div>
            </div>
            <div className="scanerblock">
                <div id="qrCodeContainer">

                </div>
            </div>
            {selectedEvent ? <div className="scanerbuttonblock">
                <button className='start-button' onClick={() => setEnabled(!isEnabled)}>
                    {isEnabled ? "Завершить сканирование" : "Начать сканирование"}
                </button>
            </div> : null}
            
        </main>
    )
}

export default Scaner