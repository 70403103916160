import Block from '../Block/Block'
import './Chalenges.css'
import djtraktor from '../../images/traktor.png'
import djmakita from '../../images/makita.png'
import djkrosh from '../../images/krosh.png'
import djdemon from '../../images/demon.png'

import React from 'react'

const Chalenges = ({setModalData, currentUserData}) => {
  console.log(currentUserData);
  return (
    <main className='Chalenges'>
        <div className="container">
            {/* <Block>
              <div className="chalengecontent">
                <h2>Голосование за лучшего диджея</h2>
                <p>Участники дискотеки решат, кто смог лучше всего завести толпу</p>
                <div className="checkblock">
                  <div className="traktorblock">
                    <img src={djtraktor} alt="djtraktor" className='checkimg' />
                    <h3>DJ Traktor</h3>
                    <button>Голосовать</button>
                  </div>
                  <div className="makitablock">
                    <img src={djmakita} alt="djmakita" className='checkimg'/>
                    <h3>DJ Makita</h3>
                    <button>Голосовать</button>
                  </div>
                  <div className="kroshblock">
                    <img src={djkrosh} alt="djkrosh" className='checkimg'/>
                    <h3>DJ Krosh</h3>
                    <button>Голосовать</button>
                  </div>
                  <div className="demonblock">
                    <img src={djdemon} alt="djdemon" className='checkimg'/>
                    <h3>DJ Demon</h3>
                    <button>Голосовать</button>
                  </div>
                </div>
              </div>
            </Block> */}
            <Block>
                <div className="devmessage">
                    <h2>Раздел в разработке</h2>
                </div>
            </Block>
        </div>
    </main>
  )
}

export default Chalenges