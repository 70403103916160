import { NavLink } from 'react-router-dom'
import API from '../../APIs/MainApi'
import Block from '../Block/Block'
import './AdminPanel.css'

import React, { useEffect, useState } from 'react'

const AdminPanel = ({setModalData}) => {

    const [curentUser, setCurentUser] = useState()
    const [login, setLogin] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [succes, setSucces] = useState('')
    const [openFunc, setOpenFunc] = useState('')

    const [isOpenConfirm, setIsOpenConfirm] = useState(false) 

    const [confirmCode, setConfirmCode] = useState('')


    //AddEvent

    const [eventTitle, setEventTitle] = useState()
    const [eventTopic, setEventTopic] = useState()
    const [eventDate, setEventDate] = useState()
    const [eventStartTime, setEventStartTime] = useState()
    const [eventOpenDorsTime, setEventOpenDorsTime] = useState()
    const [eventDuration, setEventDuration] = useState()
    const [eventPlace, setEventPlace] = useState()
    const [eventSpaceAvaileble, setEventSpaceAvaileble] = useState()
    const [eventStartReg, setEventStartReg] = useState()
    const [events, setEvents] = useState()
    const [selectedEvent, setSelectedEvents] = useState()


    const [blackList, setBlackList] = useState()

    const [ isBlackListCreateOpen, setIsBlackListCreateOpen ] = useState(false)

    const [ firstNameBlack, setFirstNameBlack ] = useState('')
    const [ secondNameBlack, setSecondNameBlack ] = useState('')
    const [ gradeNumBlack, setGradeNumBlack ] = useState('')
    const [ gradeLatterBlack, setGradeLatterBlack ] = useState('')
    const [ isGraduateBlack, setIsGraduateBlack ] = useState(false)
    const [ graduateYearBlack, setGraduateYearBlack ] = useState('')


    const handleLogin = () => {
        setError('')
        API.getadmindata(login, password).then((data) => {
            setCurentUser(data)
        }).catch((e) => {
            console.log(e.response);
            setError(e.response.data.message)
        })
    }
    const handleCreateEvent = () => {
        setError('')
        API.addevent( curentUser.confirmedLogin, curentUser.confirmedAccesKey, confirmCode, eventTitle, eventTopic, eventDate, eventStartTime, eventDuration, eventPlace, eventSpaceAvaileble, eventStartReg, eventOpenDorsTime).then((data)=> {
            setSucces(data.message)
        }).catch((e)=> {
            console.log(e);
            setError(e.response.data.message)
        })

        setIsOpenConfirm(false)
    }
    const openConfirm = () => {
        setIsOpenConfirm(true)
    }
    const closeConfirm = () => {
        setIsOpenConfirm(false)
    }
    const loadEvents =() => {
        API.getevents().then((data) => {
            setEvents(data)
        }).catch((e) => {
            console.log(e);
        })
    }

    const loadBlackList = () => {
        API.getblacklist(curentUser.confirmedLogin, curentUser.confirmedAccesKey).then((data) => {
            console.log(data);
            setBlackList(data.blacklist)
        }).catch((e) => {
            console.log(e);
            setModalData({message: "Ошибка загрузки чс", type: "error"})
        })
    }

    const openBlackListAdder = () => {
        setIsBlackListCreateOpen(true)
    }

    const closeBlackListAdder = () => {
        setIsBlackListCreateOpen(false)
    }

    const addBlackList = () => {
        API.addblacklist(firstNameBlack, secondNameBlack, gradeNumBlack, gradeLatterBlack, isGraduateBlack, graduateYearBlack, curentUser.confirmedLogin, curentUser.confirmedAccesKey).then((data) => {
            console.log(data);
            setModalData({message: "ЧС обновлен", type: "ok"})
            loadBlackList()
        }).catch((e) => {
            console.log(e);
            setModalData({message: "Ошибка добавления ЧС", type: "error"})
        })
    }

    const openCheckStudents = () => {
        loadEvents()
        setTimeout(setOpenFunc('watchMembers'), 3000)
        console.log(events);
    }

    const openEditEvents = () => {
        loadEvents()
        setTimeout(setOpenFunc('editEvent'), 3000)
        console.log(events);
    }

    const openBlackList = () => {
        loadBlackList()
        setTimeout(setOpenFunc('editBlackList'), 3000)
    }

    useEffect(() => {
        if (openFunc == "editBlackList") {
            loadBlackList()
            console.log(blackList);
        }
    }, [openFunc])



    const deleteEvent = () => {
        console.log(selectedEvent);
        API.deleteevent(curentUser.confirmedLogin, curentUser.confirmedAccesKey, confirmCode, selectedEvent).then((data) => {
            console.log(data);
            setModalData({message: "Мероприятие удалено", type: "ok"})
        }).catch((e)=> {
            console.log(e);
            setModalData({message: "Ошибка удаления", type: "error"})
        })
        loadEvents()
    }

    const deleteMember = (memberID, eventID) => {
        API.deletemember(memberID, eventID, curentUser.confirmedLogin, curentUser.confirmedAccesKey,).then((data) => {
            console.log(data);
            setModalData({message: "Участник удален", type: "ok"})
        }).catch((e) => {
            console.log(e);
            setModalData({message: "Ошибка удаления", type: "error"})
        })
        loadEvents()
    }

    const deleteBlack = (id) => {
        API.deleteblack(id, curentUser.confirmedLogin, curentUser.confirmedAccesKey).then((data) => {
            console.log(data);
            setModalData({message: "Участник удален из чс", type: "ok"})
            loadBlackList()
        }).catch((e) => {
            console.log(e);
            setModalData({message: "Ошибка удаления из чс", type: "error"})
        })
    }

  return (
    <main className={'AdminPanel'}>
        {curentUser
        ? <div className="container">
            <div className="AdminPanelUI">
            <Block><h2 className='AdminPanelWelcome'>Привет, {curentUser.userName}</h2></Block>
            <Block>
                <div className="AdminFuncPanel">
                    <a className='AdminFuncPanelButtons' href="#createEvent" onClick={() => setOpenFunc('createEvent')}>Создать мероприятие</a>
                    <a className='AdminFuncPanelButtons' href="#editEvent" onClick={() => openEditEvents()}>Редактировать мероприятие</a>
                    <a className='AdminFuncPanelButtons' href="#watchMembers" onClick={() => openCheckStudents()}>Смотреть участников</a>
                    <a className='AdminFuncPanelButtons' href="#editBlackList" onClick={() => openBlackList()}>Управлять чёрным списком</a>
                    <NavLink className={'AdminFuncPanelButtons'} to={'/scaner'}>QR-сканер</NavLink>
                </div>
            </Block>
            {openFunc === 'createEvent'
            ? 
                <Block>
                    <div className="AdminPanelCreateEvent" id='createEvent'>
                        <h2 className='AdminPanelWelcome'>Создать мероприятие</h2>
                        <p>Введите данные мероприятия</p>
                        <input type="text" disabled={isOpenConfirm} placeholder='Название' value={eventTitle || ''} onChange={(e) => setEventTitle(e.target.value)}/>
                        <input type="text" disabled={isOpenConfirm} placeholder='Тематика' value={eventTopic || ''} onChange={(e) => setEventTopic(e.target.value)}/>
                        <input type="text" disabled={isOpenConfirm} placeholder='Дата (в формате 22.02.24)' value={eventDate || ''} onChange={(e) => setEventDate(e.target.value)}/>
                        <input type="text" disabled={isOpenConfirm} placeholder='Время начала (в формате 16:00)' value={eventStartTime || ''} onChange={(e) => setEventStartTime(e.target.value)}/>
                        <input type="text" disabled={isOpenConfirm} placeholder='Запуск в зал (за 30 минут)' value={eventOpenDorsTime || ''} onChange={(e) => setEventOpenDorsTime(e.target.value)}/>
                        <input type="text" placeholder='Продолжительность (в формате 4:00)' value={eventDuration || ''} onChange={(e) => setEventDuration(e.target.value)}/>
                        <input type="text" disabled={isOpenConfirm} placeholder='Место проведения' value={eventPlace || ''} onChange={(e) => setEventPlace(e.target.value)}/>
                        <input type="number" disabled={isOpenConfirm} placeholder='Кол-во мест' value={eventSpaceAvaileble || ''} onChange={(e) => setEventSpaceAvaileble(e.target.value)}/>
                        <input type="text" disabled={isOpenConfirm} placeholder='Начало регистрации (в формате 22.02.24)' value={eventStartReg || ''} onChange={(e) => setEventStartReg(e.target.value)}/>
                        {!isOpenConfirm 
                        ?<button className='AdminSignInButton' onClick={() => openConfirm()} disabled={!eventTitle || !eventTopic || !eventDate || !eventStartTime || !eventOpenDorsTime || !eventDuration || !eventPlace || !eventSpaceAvaileble || !eventStartReg}>Создать</button>
                        : <div className="confirmBlock">
                            <input type="text" className='confirmCodeInput' placeholder='Код' value={confirmCode} onChange={(e) => setConfirmCode(e.target.value)}/>
                            <div className="confirmbuttons">
                                <button className='confirmButton' disabled={confirmCode.length !== 4} onClick={() => handleCreateEvent()}>Подтвердить</button>
                                <button className='rejectButton' onClick={() => closeConfirm()}>Отмена</button>
                            </div>
                        </div>
                        }
                        {error !== '' ? <span className='AdminSignInError'>{error}</span> : null }
                        {succes !== '' ? <span className='AdminSignInSucces'>{succes}</span> : null }
                        </div>
                </Block>
            
            : null
            }
            {openFunc === 'editEvent'
            ? 
                <Block>
                    <div className="AdminPanelEditEvent" id='editEvent'>
                        <h2 className='AdminPanelWelcome'>Редактировать мероприятие</h2>
                        <p>Выберете мероприятие</p>
                        <select name="events" value={selectedEvent ? selectedEvent : 'Мероприятие' } onChange={(e) => setSelectedEvents(e.target.value)} className='eventSelecter'>
                        <option defaultChecked disabled>Мероприятие</option>
                            {events?.map((event) => 
                                <option value={event._id} key={event._id}>{event.title}</option>
                            )}
                        </select>

                        {selectedEvent ? <button  onClick={() => openConfirm()}>Удалить мероприятие</button> : null}
                        {!isOpenConfirm 
                            ?<button className='AdminSignInButton' onClick={() => openConfirm()} disabled={!eventTitle || !eventTopic || !eventDate || !eventStartTime || !eventOpenDorsTime || !eventDuration || !eventPlace || !eventSpaceAvaileble || !eventStartReg}>Создать</button>
                            : <div className="confirmBlock">
                                <input type="text" className='confirmCodeInput' placeholder='Код' value={confirmCode} onChange={(e) => setConfirmCode(e.target.value)}/>
                                <div className="confirmbuttons">
                                    <button className='confirmButton' disabled={confirmCode.length !== 4} onClick={() => deleteEvent()}>Подтвердить</button>
                                    <button className='rejectButton' onClick={() => closeConfirm()}>Отмена</button>
                                </div>
                            </div>
                        }

                    </div>
                </Block>
            
            : null
            }
            {openFunc === 'watchMembers'
            ? 
                <Block>
                    <div className="AdminPanelWatchMembers" id='watchMembers'>
                        <h2 className='AdminPanelWelcome'>Посмотреть участников</h2>
                        <p>Выберете мероприятие</p>
                        <select name="events" value={selectedEvent ? selectedEvent : 'Мероприятие' } onChange={(e) => setSelectedEvents(e.target.value)} className='eventSelecter'>
                            <option defaultChecked disabled>Мероприятие</option>
                            {events?.map((event) => 
                                <option value={event._id} key={event._id}>{event.title}</option>
                            )}
                        </select>

                        {selectedEvent ? 
                            <div className="eventMembers">
                                 <p>{events?.find(event => event._id === selectedEvent)?.members?.length}</p>
                                {events?.find(event => event._id === selectedEvent)?.members?.map((member, index) => 
                                <div className="eventMember" key={member._id}>
                                    <p>{index+1}. {member?.firstName} {member?.secondName} {member?.thirdName ? member?.thirdName : null} {member?.gradeNum ? member?.gradeNum : null} {member?.gradeLatter ? member?.gradeLatter : null} {member?.isGraduate ? member?.isGraduate : null} {member?.graduateYear ? member?.graduateYear : null}</p>
                                    <div className="eventMemberButtons">
                                        <button className='eventMemberButton'>✏️</button>
                                        <button className='eventMemberButton' onClick={() => deleteMember(member.userID, selectedEvent)}>❌</button>
                                    </div>
                                </div>
                                    
                                )}
                            </div>
                        : null}

                        


                    </div>
                </Block>
            
            : null
            }
            {openFunc === 'editBlackList'
            ? 
                <Block>
                    <div className="AdminPanelEditBlackList" id='editBlackList'>
                        <h2 className='AdminPanelWelcome'>Редактировать черный список</h2>
                        {isBlackListCreateOpen ? <button onClick={() => closeBlackListAdder() }>Закрыть</button> : <button onClick={() => openBlackListAdder()}>Добавить чс</button>}
                        {isBlackListCreateOpen ? 
                            <div>
                                <input type="text" placeholder='Имя' value={firstNameBlack || ''} onChange={(e) => setFirstNameBlack(e.target.value)}/>
                                <input type="text" placeholder='Фамилия' value={secondNameBlack || ''} onChange={(e) => setSecondNameBlack(e.target.value)}/>
                                <input type="number" placeholder='Класс' value={gradeNumBlack || ''} onChange={(e) => setGradeNumBlack(e.target.value)}/>
                                <input type="text" placeholder='Буква класса' value={gradeLatterBlack || ''} onChange={(e) => setGradeLatterBlack(e.target.value)}/>
                        {/* <div>
                        <input type="checkbox" placeholder='' value={isGraduateBlack || ''} onChange={(e) => setIsGraduateBlack(e.target.value)}/>
                        <p>Выпускник?</p>
                        </div>
                        <input type="text" placeholder='Год выпуска' value={eventOpenDorsTime || ''} onChange={(e) => setEventOpenDorsTime(e.target.value)}/> */}
                        <button onClick={() => addBlackList()}>Добавить</button>
                            </div>
                        : null}
                        {blackList?.length != 0 ? blackList?.map((black, index) => <div className="eventMember" key={black?._id}>
                            <p>{index+1}. {black?.firstName} {black?.secondName} {black?.gradeNum ? black?.gradeNum : null} {black?.gradeLetter ? black?.gradeLetter : null} {black?.isGraduate ? black?.isGraduate : null} {black?.graduateYear ? black?.graduateYear : null}</p>
                            <div className="eventMemberButtons">
                                    <button className='eventMemberButton'>✏️</button>
                                    <button className='eventMemberButton' onClick={() => deleteBlack(black?._id)}>❌</button>
                            </div>
                        </div>

                        ): null}
                        
                    </div>
                </Block>
            
            : null
            }
        </div>
        </div>
        : 
            <div className="container">
                <Block>
                    <div className="AdminSignInPanel">
                        <h1>Рады видеть</h1>
                        <input type="text" className='AdminSignInInput' value={login} onChange={(e) => {setLogin(e.target.value)}} placeholder='Логин'/>
                        <input type="password" className='AdminSignInInput' value={password} onChange={(e) => {setPassword(e.target.value)}} placeholder='Пароль'/>
                        <button className='AdminSignInButton' onClick={() => handleLogin()} disabled={!login || !password}>Войти</button>
                        {error !== '' ? <span className='AdminSignInError'>{error}</span> : null }
                    </div>
                </Block>
            
            </div>
        }
    </main>
  )
}

export default AdminPanel