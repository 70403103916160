import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../Context/UserContext';
import QRCode from 'react-qr-code';
import './QRBlock.css'
import Preloader from '../Preloader/Preloader';
import API from '../../APIs/MainApi';
import { useTelegram } from '../../hooks/useTelegram';

const QRBlock = ({Event, setModalData, isLoadedNearestEvent}) => {

    const [qrData, setQRData] = useState()
    const [memberCard, setMemberCard] = useState()
    const [ isLoading, setIsLoading ] = useState(true)
    const [succesMessage, setSuccesMessage] = useState()

    const context = useContext(UserContext)

    const { user } = useTelegram()

    useEffect(() => {
        console.log(isLoadedNearestEvent);
        console.log(Event);
        console.log(context?.userIDContext);
    }, [isLoadedNearestEvent])

    useEffect(() => {
        if (isLoadedNearestEvent) {
            if(context?.userIDContext){
                const memberCard = Event?.members?.find(o => o?.userID == context?.userIDContext)
                const memberCardID = memberCard?._id
                setQRData(memberCardID)
                setMemberCard(memberCard)
                setIsLoading(false)
            }
        }
    }, [Event, isLoadedNearestEvent])



    const sendToChat = () => {

        //API.sendqr(chat.id, data.memberData) 

        API.sendqr(user.id, memberCard, Event._id).then((data) => {
            // setModalData({message: `Отправленно, ${user.id}`, type: "ok"})
            setSuccesMessage(data.message)
        }).catch((e) => {
            setModalData({message: e, type: "ok"})
            console.log(e);
        })
    }



  return (
    <section className='QRBlock'>

        {isLoading ? <div className="qrLoading">
            <Preloader />
        </div> : 

        Event && qrData ? 

        <div className="QRBlockData">
            <h3 className='QRBlockDataTitle'>Ваш пропуск на мероприятие</h3>
            <h4>{Event.title}</h4>
            <div className="QRCodeBlock">
                <QRCode className="QRCode" value={qrData}/>
            </div>
            <p className='QRMemberData'>{memberCard.firstName} {memberCard.secondName} {memberCard.gradeNum}{memberCard.gradeLatter}</p>
            <p className='QRMemberCardID'>{memberCard._id}</p>
            <button className='sendQRToChat' onClick={() => sendToChat()}>Отправить в чат</button>
            <span className='succesMessage'>{succesMessage}</span>
            <p className="QRPrompt">Откройте приложение на пропускном пункте и покажите QR-код</p>
        </div>


        : null}
    </section>
  )
}

export default QRBlock